// =================================================
// PARKING

// ██████╗  █████╗ ██████╗ ██╗  ██╗██╗███╗   ██╗ ██████╗
// ██╔══██╗██╔══██╗██╔══██╗██║ ██╔╝██║████╗  ██║██╔════╝
// ██████╔╝███████║██████╔╝█████╔╝ ██║██╔██╗ ██║██║  ███╗
// ██╔═══╝ ██╔══██║██╔══██╗██╔═██╗ ██║██║╚██╗██║██║   ██║
// ██║     ██║  ██║██║  ██║██║  ██╗██║██║ ╚████║╚██████╔╝
// ╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝

// =================================================

.parking-level-name {
    color: @purple;
    font-weight: @heavy-weight;
    border-bottom: 2px solid @border-color;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

span.parking-level-description {
    font-weight: normal;
    font-style: italic;
}

.parking-legend-title {
    font-size: 12px;

    &.occupied {
        color: @red;
    }

    &.free {
        color: @green;
    }
}

.parking-legend-value {
    font-size: 16px;

    font-weight: @heavy-weight;

    &.occupied {
        color: @red;
    }

    & > span {
        &.small {
            font-size: 12px;
            opacity: 0.8;
        }

        &.occupied {
            color: @red;
        }

        &.free {
            color: @green;
        }
    }
}

// =================================================
// CARDS

// ██████╗ █████╗ ██████╗ ██████╗ ███████╗
// ██╔════╝██╔══██╗██╔══██╗██╔══██╗██╔════╝
// ██║     ███████║██████╔╝██║  ██║███████╗
// ██║     ██╔══██║██╔══██╗██║  ██║╚════██║
// ╚██████╗██║  ██║██║  ██║██████╔╝███████║
//  ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝
// =================================================
.dashboard-card-header {
    & > .rs-panel-heading {
        background-color: @background-dark;
        color: @white;
        padding: 15px 20px;
    }
}

// =================================================
// STATUS CARD

// ███████╗████████╗ █████╗ ████████╗██╗   ██╗███████╗     ██████╗ █████╗ ██████╗ ██████╗ ███████╗
// ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║   ██║██╔════╝    ██╔════╝██╔══██╗██╔══██╗██╔══██╗██╔════╝
// ███████╗   ██║   ███████║   ██║   ██║   ██║███████╗    ██║     ███████║██████╔╝██║  ██║███████╗
// ╚════██║   ██║   ██╔══██║   ██║   ██║   ██║╚════██║    ██║     ██╔══██║██╔══██╗██║  ██║╚════██║
// ███████║   ██║   ██║  ██║   ██║   ╚██████╔╝███████║    ╚██████╗██║  ██║██║  ██║██████╔╝███████║
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚══════╝     ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝
// =================================================

.status-card {
    background-color: @blue;
    color: @white;

    & > .rs-panel-body {
        & > h6 {
            padding: 8px 16px;
        }

        & > h4 {
            padding: 6px 16px;
        }

        & > .rs-progress {
            padding: 0px 0px;

            .rs-progress-line-outer {
                border-radius: 0;

                .rs-progress-line-inner {
                    border-radius: 0;

                    .rs-progress-line-bg {
                        border-radius: 0;
                    }
                }
            }
        }

        & > .bg-icon {
            float: right;
            font-size: 84px;
            color: #000;
            opacity: 0.25;
            position: absolute;
            right: 15px;
            bottom: 0;
            top: 25%;
            overflow: hidden;
            z-index: 0;
        }

        & > .rotated {
            float: right;
            font-size: 68px;
            color: #000;
            opacity: 0.25;
            position: absolute;
            right: 0;
            bottom: 10;
            top: 30%;
            overflow: hidden;
            z-index: 0;
        }
    }
}

.dashboard-card-overflow > .rs-panel-body {
    height: calc(100vh * 0.43 - 95px);
    overflow-y: scroll;
}
