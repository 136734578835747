.rs-table-row:nth-child(even) > .rs-table-cell-group > .rs-table-cell {
    background-color: #f2f2f2;
}

.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:hover .rs-table-cell {
    background: #f2faff;
}

.rs-table-cell-content > .rs-btn-toggle,
.rs-input-group {
    margin-top: -5px;
}

.table-element-count {
    margin-left: 5px;
    margin-right: 5px;

    .rs-loader > .rs-loader-spin {
        margin-top: 5px;
    }
}

.display-table > .rs-table-body-row-wrapper > .rs-table-body-wheel-area > .rs-table-row {
    height: 60px !important;
    & > .rs-table-cell-group > .rs-table-cell {
        padding-top: 5px;
    }
}

.table-camera-place > div > div > div > .rdt_TableBody > div {
    min-height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
}

.form-table > .rs-table-cell-content {
    padding: 5px;
    margin: 0;

    & > .rs-input-group {
        margin-top: 0;
    }
}
