
.list-ipCan-devices {
    display: block;
}

.ipcan-legend-value {
    font-size: 16px;
    font-weight: @heavy-weight;

    &.occupied {
        color: @red;
    }

    & > span {
        &.small {
            font-size: 12px;
            opacity: 0.8;
        }
    }
}

.ipcan-legend-title {
    font-size: 12px;
}