.rs-drawer-header {
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 10px;

    .rs-drawer-header-close {
        right: 20px;
        top: 0;
    }
}

.rs-drawer-content {
    background-color: #eeeeee;

    & > .drawer-body-with-avatar {
        height: calc(100% - 123px) !important;
    }

    & > .drawer-body-without-avatar {
        height: calc(100% - 103px) !important;
    }

    & > .rs-drawer-body {
        margin: 5px 0px 0px 0px;
        padding: 0px 20px 15px 20px;
    }

    & > .rs-drawer-footer {
        border-top: 1px solid #bdbdbd;
        margin: 0;
        padding: 7px 20px 10px 20px;
    }
}

.drawer-no-footer > .rs-drawer-dialog > .rs-drawer-content > .rs-drawer-body {
    height: 95% !important;
}
