.fab {
    position: fixed !important;
    bottom: 30px;
    right: 30px;
    width: 50px !important;
    height: 50px !important;

    & > .rs-icon {
        width: 50px !important;
        height: 50px !important;
        padding: 15px 0 !important;
    }
}

.button-icon-black {
    background-color: @background-dark;
    color: @white;

    & > i {
        background-color: @background-dark !important;
    }

    &:hover {
        background-color: rgba(@background-dark, 0.8);
        color: @white;

        & > i {
            background-color: @background-dark !important;
        }
    }
}

.button-icon-redGreen {
    background: linear-gradient(to right, @red, @green);
    color: @white;

    & > i {
        background-color: @red !important;
    }

    &:hover {
        background: linear-gradient(to right, @red, @green);
        color: @white;

        & > i {
            background-color: @red !important;
        }
    }
}

.button-icon-redBlink {
    & > i {
        animation-name: blinking;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        background-color: @red !important;
    }

    background-color: @red;
    color: @white;

    &:hover {
        color: @white;
        background-color: @red;
    }
}

@keyframes red-blink-animation {
    to {
        color: @red;
    }
}
@-webkit-keyframes red-blink-animation {
    to {
        color: @white;
    }
}

.button-icon-greenBlink {
    & > i {
        animation-name: blinking;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        background-color: @green !important;
    }

    background-color: @green !important;
    color: @white;

    &:hover {
        color: @white;
    }
}

@keyframes blinking {
    from {
        color: rgba(255, 255, 255, 1);
    }

    to {
        color: rgba(255, 255, 255, 0.2);
    }
}

@keyframes blinkingGreenToRed {
    from {
        color: @green;
    }

    to {
        color: @red;
    }
}

.rs-btn-ghost.button-map {
    background-color: @white;
    color: @background-dark;

    &.redBlink {
        animation-name: redBlink;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
}

.rs-btn-primary.button-map {
    padding: 7px 12px;
    border: 1px solid transparent;

    & > svg {
        width: 1.125em;
    }
}

.button-blink-yellow {
    background-color: #ffca28;
    animation-name: yellowBlink;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    & > svg {
        animation-name: shake;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: ease;
    }
}

.button-blink-orange {
    background-color: #ff9800;
    animation-name: orangeBlink;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    & > svg {
        animation-name: shake;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: ease;
    }
}

@keyframes yellowBlink {
    from {
        background-color: #ffca28;
    }

    to {
        background-color: @red;
    }
}

@keyframes orangeBlink {
    from {
        background-color: #ff9800;
    }

    to {
        background-color: @red;
    }
}

@keyframes redBlink {
    from {
        background-color: @white;
        color: @background-dark;
    }

    to {
        background-color: @red;
        color: @white;
    }
}

@keyframes shake {
    5%,
    45% {
        transform: translate3d(-1px, 0, 0);
    }

    10%,
    40% {
        transform: translate3d(2px, 0, 0);
    }

    15%,
    25%,
    35% {
        transform: translate3d(-3px, 0, 0);
    }

    20%,
    30% {
        transform: translate3d(3px, 0, 0);
    }
}