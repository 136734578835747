.login-page {
    height: 100%;
    color: white;

    .full-height {
        height: 100%;
    }

    .logo {
        height: 100%;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: block;
    }

    .demo-parking {
        background-image: url('../../assets/demo_parking.png');
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .big-shadow {
        box-shadow: 8px 8px 2px 1px rgba(0, 0, 0, 0.25);
    }

    .rs-content {
        height: 100%;
    }

    .rs-panel {
        background: rgba(26, 29, 36, 0.9);
        margin-top: 30%;
    }
}
