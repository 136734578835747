.list-transparent {
    background-color: transparent;
    color: white;
    box-shadow: none;
    text-shadow: 0 10px 10px @shadow-color, 0 0 10px rgba(0, 0, 0, 0.06);
    font-size: 16px;
}

.list-transparent-black-text {
    background-color: transparent;
    color: @background-dark;
    box-shadow: none;
    text-shadow: 0 10px 10px @shadow-color, 0 0 10px rgba(0, 0, 0, 0.06);
    font-size: 16px;
}

.list-small {
    font-size: 14px !important;
    background-color: @light-blue;
}

.list-placeType > div:not(:last-child) {
    border-bottom: 1px solid #9e9e9e;
}

.striped {
    background-color: rgba(0, 0, 0, 0.06);
    box-shadow: none;
}

.list-green {
    background-color: rgb(@green, 0.3);

    &:hover {
        background-color: rgba(@green, 0.5) !important;
    }
}

.list-red {
    background-color: rgba(@red, 0.3);

    &:hover {
        background-color: rgba(@red, 0.5) !important;
    }
}

.separator-filter {
    background-color: #757575 !important;
    color: #fff;
    font-size: 16px;
    font-weight: 'bold';
    padding-top: 5px;
    padding-bottom: 5px;
}

.list-profile-section {
    background-color: #757575 !important;
    color: #fff;
    font-size: 16px;
    font-weight: 'bold';
    padding-top: 8px;
    padding-bottom: 8px;
}

.list-profile-role {
    padding-top: 8px;
    padding-bottom: 8px;
}

.list-text-smaller {
    font-size: 13px !important;
}

.list-colored-blue {
    background-color: rgba(107, 174, 217, 0.3);

    &:hover {
        background-color: rgba(107, 174, 217, 0.7) !important;
    }
}

.list-colored-yellow {
    background-color: rgba(255, 252, 181, 0.3);

    &:hover {
        background-color: rgba(255, 252, 181, 0.7) !important;
    }
}

.list-colored-aero {
    background-color: rgba(189, 241, 211, 0.3);

    &:hover {
        background-color: rgba(189, 241, 211, 0.7) !important;
    }
}

.list-colored-violet {
    background-color: rgba(169, 116, 219, 0.3);

    &:hover {
        background-color: rgba(169, 116, 219, 0.7) !important;
    }
}

.sensor-to-copy {
    font-weight: bold;
    background-color: rgba(0, 200, 0, 0.4);
    text-align: center;
}

.sensor-to-be-copied {
    background-color: rgba(128, 0, 128, 0.4);
    font-weight: bold;
    text-align: center;
}

.list-disabled {
    background-color: grey;
}

.vl {
    border-right: 2px solid rgba(@background-dark, 0.7);
    line-height: 2;
    height: 100%;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
}

.list-step-disabled {
    background-color: #b1b1b7;
}

.list-step-waiting {
    background-color: rgba(#ffa500, 0.2);
}
