.task-color-black {
    background-color: black;
    color: #ffffff;
}

.task-color-red {
    background-color: @red;
    color: #ffffff;
}

.task-color-green {
    background-color: @green;
    color: #ffffff;
}

.task-color-green-blink {
    .animation-blink('blink-green', @green, black);
    color: #ffffff;
}

.task-color-red-blink {
    .animation-blink('blink-red', @red, black);
    color: #ffffff;
}

.task-color-red-green-blink {
    .animation-blink('blink-red-green', @red, @green);
    color: #ffffff;
}

.animation-blink(@name_, @color1, @color2, @time: 1s) {
    @name: ~'animation-blink-@{name_}';
    animation: @name @time ease-in-out infinite alternate;

    @keyframes @name {
        0% {
            background-color: @color1;
        }
        to {
            background-color: @color2;
        }
    }
}

.event-select-type {
    border: 3px solid #000;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: #000;
    border-radius: 10px;

    & > .icon {
        font-size: 64px;
        color: #000;
    }

    &.selected {
        border: 3px solid #017aff;
        color: #017aff;

        & > .icon {
            color: #017aff;
        }
    }

    &:hover {
        cursor: pointer;
    }
}
