.changelog {
    h1 {
        font-size: 28px;
        line-height: 32px;
    }

    h2 {
        font-size: 24px;
        line-height: 28px;
        margin-top: 6px;
    }

    h3 {
        font-size: 20px;
        line-height: 24px;
        margin-top: 6px;
    }

    h4 {
        font-size: 18px;
        line-height: 22px;
        margin-top: 6px;
    }

    h5 {
        font-size: 16px;
        line-height: 20px;
        margin-top: 6px;
    }
}
