.rs-panel {
    background-color: #fff;
    margin-bottom: 15px;

    &.panel-big {
        & > .rs-panel-heading {
            color: #fff;
            font-size: 26px;
            line-height: 1;

            .rs-tag {
                font-size: 16px;
            }
        }

        &.element-table {
            background-image: linear-gradient(to left, #2184da, #3f87c5);
        }

        &.user-table {
            background-image: linear-gradient(to left, #6a1b9a, #8e24aa);
        }

        &.parking-table {
            background-image: linear-gradient(to left, #283593, #3949ab);
        }

        &.configuration-database-table {
            background-image: linear-gradient(to left, #42a5f5, #64b5f6);
        }

        &.configuration-version-table {
            background-image: linear-gradient(to left, #ffa726, #ffb74d);
        }

        &.configuration-advanced-table {
            background-image: linear-gradient(to left, #ef5350, #e57373);
        }
    }
}

.panel-small {
    & > .rs-panel-heading {
        padding: 10px 20px;
        // border-bottom: 1px solid #e5e5ea;
        border-bottom: none;
        background-color: @background-dark;
        color: @white;

        .title {
            margin-top: 5px;
        }

        &::before {
            top: 10px;
        }

        & > div > .title > .tag-panel-header {
            font-size: 12px;
            margin-left: 10px;
            line-height: 1.25;
        }
    }

    & > .rs-panel-body-fill > .rs-placeholder {
        margin: 20px;
    }
}

.panel-sticky > .rs-panel-heading {
    top: 0px;
    position: sticky;
    z-index: 100;
}

.panel-small-progress {
    margin-bottom: 2px;

    & > .rs-panel-heading {
        padding: 5px 5px;
        background-color: @background-dark;
        color: @white;

        .title {
            margin-top: 5px;
        }

        &::before {
            top: 10px;
        }
    }

    & > .rs-panel-body {
        padding: 5px 5px;
    }
}

.tag-panel-header {
    font-size: 20px;
    margin-left: 10px;
}

.panel-list {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
}

.panel-table {
    padding-top: 0;
    padding-bottom: 0;
}

.disable {
    pointer-events: none;
    opacity: 0.4;

    .keyframes(fade-out; {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0.4;
            }
        }

    );

    .animation(fade-out 0.5s linear);
}

.keyframes(@name; @arguments) {
    @-moz-keyframes @name {
        @arguments();
    }

    @-webkit-keyframes @name {
        @arguments();
    }

    @keyframes @name {
        @arguments();
    }
}

.animation(@arguments) {
    -webkit-animation: @arguments;
    -moz-animation: @arguments;
    animation: @arguments;
}

.rs-panel-collapsible-no-carret > .rs-panel-heading::before {
    display: none;
}

.panel-errors > .rs-panel-heading {
    background-color: #ffe0b2;
}

.panel-error-title > .rs-panel-heading {
    padding: 15px 20px;
    border: none;
    background-color: @background-dark;
    color: @white;

    .title {
        margin-top: 5px;
    }

    &::before {
        top: 15px;
    }
}

.panel-error-bus > .rs-panel-heading {
    padding: 10px 20px;
    border: none;

    .title {
        margin-top: 5px;
    }

    &::before {
        top: 10px;
    }
}

.rs-panel-group .rs-panel + .rs-panel::before {
    left: 0;
    right: 0;
}

.panel-list-error {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.panel-placeType {
    background-image: linear-gradient(to left, #263238, #37474f);
}

.panel-counter {
    background-image: linear-gradient(to left, #1b5e20, #2e7d32);
}

.panel-fixed-height {
    & > .rs-panel-body {
        overflow-y: auto;
        height: 300px;
    }
}

.panel-contains-panel {
    background-color: #ebeced;

    &:first-child {
        margin-top: 0;
    }

    & > .rs-panel-heading {
        padding: 0;

        &::before {
            top: 11px;
        }

        & > .rs-flex-box-grid > .rs-col-xs-3 {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.panel-no-padding {
    background-color: @body-bg;
    max-width: calc(100vw - 76px);

    & > .rs-panel-heading {
        padding: 3px;
        margin-bottom: 6px;
        background-color: @white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
        border: 1px solid rgba(0, 0, 0, 0.3);
    }

    & > .rs-panel-collapse > .rs-panel-body {
        & > .Loader > .Loader__content > .rs-flex-box-grid {
            background-color: @white;
            margin-top: 8px;
            margin-bottom: 8px;
            padding-bottom: 4px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            border: 1px solid rgba(0, 0, 0, 0.3);
        }
    }
}

.panel-counter-level {
    background-color: @white;
    margin-top      : 8px;
    margin-bottom   : 8px;
    padding-bottom  : 4px;
    box-shadow      : 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.panel-roles {
    max-width: calc(100vw - 76px);

    & > .rs-panel-heading {
        padding-bottom: 6px;
        padding-top: 6px;
        padding-left: 4px;
        padding-right: 4px;
        background-color: #999999;
        color: #fff;
        border-top: 1px solid #fff;
    }
}

.flex-hoverable:hover {
    background-color: @light-blue !important;
    cursor: pointer;
}

.panel-display-counter {
    margin-top: 0 !important;

    & > .rs-panel-heading {
        padding: 5px 5px;
        font-size: 16px;
        font-weight: bolder;
    }

    & > .rs-panel-body {
        background-color: rgba(@green, 0.1);
        padding-left: 5px;
    }
}

.panel-display-substraction-counter {
    margin-top: 0 !important;

    & > .rs-panel-heading {
        padding: 5px 5px;
        font-size: 16px;
        font-weight: bolder;
    }

    & > .rs-panel-body {
        background-color: rgba(@red, 0.1);
    }
}

.bg-dark {
    color: white;
    background-color: @background-dark;
}

.panel-parking > .rs-panel-heading {
    border: 3px solid @background-dark;
}

.bordered-panel {
    border: 3px solid @background-dark;
}

.panel-update {
    background-color: @small-text-color;
    margin-bottom: 0;
    border-radius: 0;
    color: #FFF
}