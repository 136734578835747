.matrix-display-node {
    border: 1px solid grey;
    padding: 5px;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow: 4px 4px 6px grey;

    &.selected {
        border: 2px solid black;
        animation: dashdraw 0.5s linear infinite;
    }

    &.topology-forced,
    &.virtual-display-forced,
    &.step-forced {
        border: 6px solid orange;
        animation: blink 1s alternate infinite;
    }

    &.step-not-counter {
        cursor: not-allowed;
    }
}

.virtual-display-step-wait {
    background-color: rgba(#ffa500, 0.2);
}

.virtual-display-step-disabled {
    background-color: #b1b1b7;
}

.virtual-display-wait {
    background-color: #b1b1b7;
}

.virtual-display-force-enabled {
    background-color: rgba(#ffa500, 0.2);
}

.react-flow__edge {
    pointer-events: all;

    &.inactive {
        pointer-events: none;
    }
    &.selected {
        .react-flow__edge-path {
            stroke: #555;
        }
    }

    &.animated path {
        stroke-dasharray: 5;
        animation: dashdraw 0.5s linear infinite;
    }

    &.updating {
        .react-flow__edge-path {
            stroke: #777;
        }
    }
}

.react-flow__edge-path {
    stroke: #b1b1b7;
    stroke-width: 10px;
}

.react-flow__node-selectorNode {
    font-size: 12px;
    background: #f0f2f3;
    border: 1px solid 555;
    border-radius: 5px;
    text-align: center;
}

.react-flow__node-selectorNode .react-flow__handle {
    border-color: #f0f2f3;
}

.react-flow {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.react-flow__renderer,
.react-flow__pane,
.react-flow__selectionpane {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.react-flow__pane {
    z-index: 1;
}

.react-flow__renderer {
    z-index: 4;
}

.react-flow__selectionpane {
    z-index: 5;
}

.react-flow__selection {
    position: absolute;
    top: 0;
    left: 0;
}

.react-flow__edges {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 2;
}

@keyframes dashdraw {
    from {
        stroke-dashoffset: 10;
    }
}

.react-flow__edge-path {
    fill: none;
}

.react-flow__edge-text {
    pointer-events: none;
    user-select: none;
}

.react-flow__connection {
    pointer-events: none;

    .animated {
        stroke-dasharray: 5;
        animation: dashdraw 0.5s linear infinite;
    }
}

.react-flow__connection-path {
    fill: none;
}

.react-flow__nodes {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform-origin: 0 0;
    z-index: 3;
}

.react-flow__node {
    position: absolute;
    user-select: none;
    pointer-events: all;
    transform-origin: 0 0;
}

.react-flow__nodesselection {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: left top;
    pointer-events: none;

    &-rect {
        position: absolute;
        pointer-events: all;
        cursor: grab;
    }
}

.react-flow__handle {
    pointer-events: none;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;

    &.connectable {
        pointer-events: all;
    }
}

.react-flow__handle-bottom {
    top: auto;
    left: 50%;
    bottom: -4px;
    transform: translate(-50%, 0);
}

.react-flow__handle-top {
    left: 50%;
    top: -4px;
    transform: translate(-50%, 0);
}

.react-flow__handle-left {
    top: 50%;
    left: -8px;
    transform: translate(0, -50%);
}

.react-flow__handle-right {
    right: -8px;
    top: 50%;
    transform: translate(0, -50%);
}

.react-flow__edgeupdater {
    cursor: move;
}

/* additional components */

.react-flow__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.react-flow__controls {
    position: absolute;
    z-index: 5;
    bottom: 10px;
    left: 10px;

    &-button {
        width: 24px;
        height: 24px;

        svg {
            width: 100%;
        }
    }
}

.react-flow__minimap {
    position: absolute;
    z-index: 5;
    bottom: 10px;
    right: 10px;

    &-node {
        shape-rendering: crispedges;
    }
}
