@import '~rsuite/lib/styles/themes/default/index.less';
@import '~leaflet-draw/dist/leaflet.draw.css';

@import './variables.less';

@import './utils/helpers.less';
@import './utils/spacing.less';
@import './utils/zindex.less';

@import './components/animations.less';
@import './components/avatar.less';
@import './components/button.less';
@import './components/changelog.less';
@import './components/drawer.less';
@import './components/eventTasks.less';
@import './components/flexbox.less';
@import './components/form.less';
@import './components/historyColor.less';
@import './components/icons.less';
@import './components/image.less';
@import './components/list.less';
@import './components/lpMatrix-display-graph.less';
@import './components/lpMatrixDisplay.less';
@import './components/map.less';
@import './components/mapDisplay.less';
@import './components/mapPassCounter.less';
@import './components/modal.less';
@import './components/navigation.less';
@import './components/panel.less';
@import './components/scrollbar.less';
@import './components/table.less';
@import './components/tag.less';

@import './pages/dashboard.less';
@import './pages/login.less';
@import './pages/users.less';
@import './pages/ipcan.less';

@import '~leaflet/dist/leaflet.css';

@import './components/leaflet-draw.less';
@icon-font-path: '~fonts';

html,
body,
#root {
    height: 100%;
    width: 100%;
    background: #e3e2ef;
    padding-bottom: 10px;
}
