.rs-tag-group {
    white-space: normal;
    text-align: center;

    &.rs-tag-group {
        margin: 0;
    }

    & > .rs-tag {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
