/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}
