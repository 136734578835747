@mapProgressBar: 170px;

.leaflet-container {
    height: calc(100vh - @mapProgressBar - 20px);
    width: 100%;
}

.mapProgressBar {
    // box-shadow: 0 2px 2px @shadow-color, 0 0 3px rgba(0, 0, 0, 0.6);
    height: @mapProgressBar;
    width: 100%;
    background-color: #a9a9a9;
    // margin-bottom: 5px;

    & > .rs-flex-box-grid-item {
        height: @mapProgressBar;
        overflow-y: auto;
    }
}

.toggleProgressBar {
    position: absolute;
    right: calc(50% - 20px);
    z-index: 1000;
    background-color: #a9a9a9;
    padding: 5px;
    cursor: pointer;
}

.rs-flex-box-grid-item {
    padding-left: 2px;
    padding-right: 2px;
}
