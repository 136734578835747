.generate-margin (@i, @n) when (@i < @n) {
    @margin: (@i * 5);

    .margin-top-@{margin} {
        margin-top: ~'@{margin}px';
    }
    .margin-bottom-@{margin} {
        margin-bottom: ~'@{margin}px';
    }
    .margin-left-@{margin} {
        margin-left: ~'@{margin}px';
    }
    .margin-right-@{margin} {
        margin-right: ~'@{margin}px';
    }

    .generate-margin(@i + 1, @n);
}

.generate-padding (@i, @n) when (@i < @n) {
    @padding: (@i * 5);

    .padding-top-@{padding} {
        padding-top: ~'@{padding}px';
    }
    .padding-bottom-@{padding} {
        padding-bottom: ~'@{padding}px';
    }
    .padding-left-@{padding} {
        padding-left: ~'@{padding}px';
    }
    .padding-right-@{padding} {
        padding-right: ~'@{padding}px';
    }

    .generate-padding(@i + 1, @n);
}

.generate-margin(1, 5);
.generate-padding(1, 5);

.padding-top-0 {
    padding-top: 0;
}

.padding-bottom-0 {
    padding-bottom: 0;
}

.padding-left-0 {
    padding-left: 0;
}

.padding-right-0 {
    padding-right: 0;
}

.margin-top-0 {
    margin-top: 0 !important;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-left-0 {
    margin-left: 0 !important;
}

.margin-right-0 {
    margin-right: 0 !important;
}

.margin-side-10 {
    margin-left: 10px;
    margin-right: 10px;
}
