.sensor-green {
    color: @green;
}

.sensor-red {
    color: @red;
}

.sensor-black {
    color: black;
}

.sensor-red-green {
    .animation-blink(blink-red-green, @red, @green);
}

.sensor-red-blink {
    .animation-blink(blink-red, @red, black);
}

.sensor-green-blink {
    .animation-blink(blink-green, @green, black);
}

.sensor-purple {
    color: @purple;
}

.animation-blink(@name_, @color1, @color2, @time: 2s) {
    @name: ~'animation-blink-@{name_}';
    animation: @name @time infinite;

    @keyframes @name {
        0% {
            color: @color1;
        }
        49% {
            color: @color1;
        }
        50% {
            color: @color2;
        }
        100% {
            color: @color2;
        }
    }
}

.history-header {
    background-color: #212121;
    padding-top: 10px;
    padding-bottom: 10px;

    & > .rs-flex-box-grid > .rs-flex-box-grid-item > p {
        color: white;
    }
}
