.flexbox-scrollable {
    flex-flow: unset;
    overflow-x: auto;
    width: 1600;
}

.rs-col {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
