.blink {
    animation-name: blink-triangle;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes blink-triangle {
    0% {
        opacity: 0;
    }

    150% {
        opacity: 1;
    }
}

.arrow-blink {
    animation: blink-animation 1s steps(2, start) infinite;
    -webkit-animation: blink-animation 1s steps(2, start) infinite;
}

.arrow-slide-in-left {
    animation: slideLeft 1s steps(144, start) infinite;
    -webkit-animation: slideLeft 1s steps(144, start) infinite;
}

.arrow-slide-in-top-left {
    animation: slideTopLeft 1s steps(144, start) infinite;
    -webkit-animation: slideTopLeft 1s steps(144, start) infinite;
}

.arrow-slide-in-top {
    animation: slideTop 1s steps(144, start) infinite;
    -webkit-animation: slideTop 1s steps(144, start) infinite;
}

.arrow-slide-in-top-right {
    animation: slideTopRight 1s steps(144, start) infinite;
    -webkit-animation: slideTopRight 1s steps(144, start) infinite;
}

.arrow-slide-in-right {
    animation: slideRight 1s steps(144, start) infinite;
    -webkit-animation: slideRight 1s steps(144, start) infinite;
}

.arrow-slide-in-bottom-right {
    animation: slideBottomRight 1s steps(144, start) infinite;
    -webkit-animation: slideBottomRight 1s steps(144, start) infinite;
}

.arrow-slide-in-bottom {
    animation: slideBottom 1s steps(144, start) infinite;
    -webkit-animation: slideBottom 1s steps(144, start) infinite;
}

.arrow-slide-in-bottom-left {
    animation: slideBottomLeft 1s steps(144, start) infinite;
    -webkit-animation: slideBottomLeft 1s steps(144, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@keyframes slideLeft {
    from {
        transform: translate3d(150%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(-150%, 0, 0);
    }
}

@keyframes slideTopLeft {
    from {
        transform: translate3d(150%, 150%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(-150%, -150%, 0);
    }
}

@keyframes slideTop {
    from {
        transform: translate3d(0%, 150%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0%, -150%, 0);
    }
}

@keyframes slideTopRight {
    from {
        transform: translate3d(-150%, 150%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(150%, -150%, 0);
    }
}

@keyframes slideRight {
    from {
        transform: translate3d(-150%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(150%, 0, 0);
    }
}

@keyframes slideBottomRight {
    from {
        transform: translate3d(-150%, -150%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(150%, 150%, 0);
    }
}

@keyframes slideBottom {
    from {
        transform: translate3d(0, -150%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 150%, 0);
    }
}

@keyframes slideBottomLeft {
    from {
        transform: translate3d(150%, -150%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(-150%, 150%, 0);
    }
}
