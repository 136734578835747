.text-center {
    text-align: center;
}

.text-upper {
    text-transform: uppercase;
}

.bold {
    font-weight: @heavy-weight;
}

.text-normal {
    font-weight: normal;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.inlined {
    display: inline-block;
}

.text-muted {
    opacity: 0.8;
}

.text-right {
    text-align: end;
}

.no-radius {
    border-radius: 0;
}

.hoverable {
    transition-property: box-shadow;
    transition-duration: 0.5s;
    cursor: pointer;

    &:hover {
        box-shadow: 0 6px 6px @shadow-color, 0 0 10px rgba(0, 0, 0, 0.06);
    }
}

.clickable {
    cursor: pointer;
    background-color: lightgray;
}

.strip {
    background-color: @strip-color;
}

.small-text-white {
    font-size: 13px;
    color: @white;
    font-style: italic;
    font-weight: normal;
}

.small-text {
    font-size: 13px;
    color: #616161;
}

.flexbox-hoverable:hover {
    background-color: #fafafa;
    cursor: pointer;
}

.text-purple {
    color: @purple;
}

.text-red {
    color: @red;
}

.text-light-red {
    color: @light-red;
}

.text-white {
    color: @white;
}

.progress-shadow > .rs-progress-line-outer {
    box-shadow: 0 5px 5px @shadow-color, 0 0 3px rgba(0, 0, 0, 0.6);
}

.background-green {
    background-color: rgba(#b0f2b6, 0.5);
}

.background-red {
    background-color: rgba(#ff6961, 0.3);
}

.popup-title {
    font-size: 16px;
    font-weight: bold;
    color: #616161;
    vertical-align: middle;
}
