.inline-block {
    display: inline-block;
}

.matrix-display-demo {
    background-color: #212121;
    padding: 1px 4px;
    color: #fff;
    width: fit-content;
    border: 1px solid #fff;
    border-radius: 5px;
    vertical-align: middle;
    margin: auto;
    margin-top: 2px;
    margin-bottom: 2px;
    min-width: 350px;

    & > * {
        // display    : inline-block;
        margin-left: 3px;
    }

    & > *:first-child {
        margin-left: 0px;
    }

    & > * > .matrix-display-demo-text {
        font-size: 22px;
        font-weight: bolder;
        text-transform: uppercase;
    }
}

.map-matrix-display-demo {
    transform: translate(-47%);
    font-weight: bold;
}

.display-forced {
    border: 3px solid orange;
    animation: blink 2s forwards infinite;
}

.display-event-forced {
    border: 3px solid #ee6352;
    animation: blinkpink 2s forwards infinite;
}
