.role-name {
    line-height: 24px;
    font-size: 18px;
}

.role-item {
    background-color: #cce9ff;
    margin: 0;
    padding: 10px 25px;
}

.role-item-hovered:hover {
    background-color: #80c4ff;
}
