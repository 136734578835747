.rs-picker-toggle-wrapper {
    width: 100%;
}

.rs-control-label {
    font-weight: bold;
}

.rs-picker-menu {
    z-index: 999;
}

.form-margin {
    margin-top: 0 !important;
}

.rs-form-vertical .rs-form-group .rs-input-group {
    width: 100% !important;
}

.rs-picker-check-menu-group-title {
    background-color: lightgray;
}

.rs-form-error {
    border: 1px solid @red;
}
