.pass-counter {
    .counter {
        min-height: 16px;
        border: 3px solid #6c757d;
        background-color: #000000;
        padding: 5px 5px;
        transform: translate(-50%, -50%);
        border-radius: 30px;
        white-space: nowrap;
        position: absolute;
        top: 50%;
        left: 50%;

        & > span {
            text-align: center;
            min-width: 90px;
            line-height: 16px;
            display: block;
            color: @map-orange;
            font-size: 14px;
            font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
            // text-transform: uppercase;
            // height: 20px;
        }
    }

    .forced {
        animation-name: blink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }
}

.pass-counter-popup {
    min-height: 30px;
    width: 135px;
    border: 3px solid #6c757d;
    background-color: #000000;
    padding: 0px 5px;
    border-radius: 3px;
    white-space: nowrap;
    display: block;
    margin-bottom: 10px;

    & > div > div {
        color: @map-orange;
        font-size: 20px;
        font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        // height: 20px;

        &.icon {
            min-width: 21px;
        }

        & > img {
            margin-top: -3px;
            width: 16px;
            height: 16px;
        }
    }
}
