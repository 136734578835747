@media only screen and (min-width: 768px) {
    .rs-sidebar-collapse {
        display: flex;
        z-index: 1000;
    }
}

@media only screen and (max-width: 768px) {
    .rs-header {
        margin-left: 0 !important;
    }

    .rs-content {
        margin-left: 10px !important;
    }

    .rs-sidenav-collapse-out {
        width: 0;
    }
}

@media only screen and (min-width: 768px) {
    .hide-on-desktop {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .hide-on-mobile {
        display: none;
    }
}

.rs-nav .rs-nav-item-active > .rs-nav-item-content:hover {
    background-color: #424242;
}

.rs-sidenav-default,
.rs-navbar.rs-navbar-default {
    background-color: #212121;

    .rs-nav-item {
        & > .rs-nav-item-content {
            &:hover,
            &:active,
            &:focus {
                background-color: #424242 !important;
                color: #fff;
            }
            & > svg {
                // font-size: 1.333333333333333333333333rem;
                height: 22px;
                width: 56px;
                margin-left: -16px;
            }
            text-overflow: unset;
        }
    }
}

.rs-sidenav-default,
.rs-navbar.rs-navbar-default.mobile {
    background-color: #212121;

    .rs-nav-item {
        & > .rs-nav-item-content {
            &:hover,
            &:active,
            &:focus {
                background-color: #424242 !important;
                color: #fff;
            }
            & > svg {
                // font-size: 1.333333333333333333333333rem;
                width: 56px;
                margin-left: -56px;
            }
        }
    }
}

.rs-navbar.rs-navbar-default .rs-nav-item > .rs-nav-item-content {
    color: #fff;
}
