.no-modal-padding > .rs-modal-dialog > .rs-modal-content {
    padding: 0;
    // border-radius: 35px;

    & > .rs-modal-header {
        padding-top: 5px;
        padding-bottom: 10px;
        color: white;
        border-radius: 6px 6px 0px 0px;

        & > .rs-modal-header-close {
            color: white;
        }
    }

    & > .rs-modal-body {
        margin-top: 10px;
        padding-bottom: 10px;
    }
}

.modal-footer-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
}
