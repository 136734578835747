.generate-zindex (@i, @n) when (@i < @n) {
    @zindex: (@i * 5);

    .zindex-@{zindex} {
        z-index: ~'@{zindex}';
    }

    .generate-zindex(@i + 1, @n);
}

.generate-zindex(1, 5);
