.tcm-display {
    .display {
        min-height: 30px;
        min-width: 95px;
        // width: 100px;
        border: 3px solid #6c757d;
        background-color: #000000;
        padding: 0px 5px;
        transform: translateX(-47%);
        border-radius: 3px;
        // white-space     : nowrap;
        width: max-content;

        & > div > div {
            color: @map-orange;
            font-size: 20px;
            font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
            text-transform: uppercase;
            // height: 20px;

            & > img {
                margin-top: -3px;
                width: 16px;
                height: 16px;
            }
        }
    }

    .event-force {
        animation-name: blinkpink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }

    .forced {
        animation-name: blink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }
}

.tcm-display-popup {
    min-height: 30px;
    min-width: 135px;
    border: 3px solid #6c757d;
    background-color: #000000;
    padding: 0px 5px;
    border-radius: 3px;
    white-space: nowrap;
    display: block;
    margin-bottom: 10px;

    & > div > div {
        color: @map-orange;
        font-size: 20px;
        font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        // height: 20px;

        &.icon {
            min-width: 21px;
        }

        & > img {
            margin-top: -3px;
            width: 16px;
            height: 16px;
        }
    }
}

@keyframes blink {
    from {
        border-color: orange;
    }

    to {
        border-color: black;
    }
}

@keyframes blinkpink {
    from {
        border-color: #ee6352;
    }

    to {
        border-color: black;
    }
}
